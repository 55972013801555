import {
  FC,
  Fragment,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import ScrollToBottom, { useScrollToBottom } from 'react-scroll-to-bottom'

import { useGlobalChat } from 'domains/Ai/GlobalChatProvider'
import { InChatSuggestedPrompts } from 'domains/Ai/SuggestedPrompts'

import { cn } from 'utils/tailwind'

import { MessageCard } from '../MessageCard'
import { Preamble } from '../Preamble'
import { ScrollableChatContainer } from '../ScrollableChatContainer'
import { useStreamingText } from '../useStreamingText'
import { ProgressInput } from './ProgressInput'
import { productTour } from './tourConfig'

const MessageCardMemoized = memo(MessageCard)

interface ProductTourProps {
  isFullscreen: boolean
}

export const ProductTour: FC<ProductTourProps> = ({ isFullscreen }) => {
  const {
    setShowProductTourView,
    isExpanded,
    productTourCurrentStep,
    setProductTourCurrentStep,
    productTourAnsweredQuestions,
    setProductTourAnsweredQuestions
  } = useGlobalChat()
  const scrollToBottom = useScrollToBottom()
  const [streamedText, setStreamedText, isStreaming] = useStreamingText()
  const [suggestedPromptsLoading, setSuggestedPromptsLoading] = useState(false)

  const questionsAnsweredOnThisStep = useMemo(() => {
    return productTour[productTourCurrentStep].followupQuestions
      .filter((q) => productTourAnsweredQuestions.includes(q.id))
      .sort((a, b) => {
        return (
          productTourAnsweredQuestions.indexOf(a.id) -
          productTourAnsweredQuestions.indexOf(b.id)
        )
      })
  }, [productTourAnsweredQuestions, productTourCurrentStep])

  const remainingQuestions = useMemo(() => {
    return productTour[productTourCurrentStep].followupQuestions.filter(
      (q) => !productTourAnsweredQuestions.includes(q.id)
    )
  }, [productTourAnsweredQuestions, productTourCurrentStep])

  useLayoutEffect(() => {
    scrollToBottom({ behavior: 'smooth' })
  }, [scrollToBottom, streamedText])

  useEffect(() => {
    setStreamedText('')
    setSuggestedPromptsLoading(true)
    const timeout = setTimeout(() => {
      setSuggestedPromptsLoading(false)
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [productTourCurrentStep, setStreamedText])

  useEffect(() => {
    if (!isStreaming) {
      setSuggestedPromptsLoading(true)
      const timeout = setTimeout(() => {
        setSuggestedPromptsLoading(false)
      }, 1000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [isStreaming])

  const handleClick = useCallback(
    (message: string) => {
      const question = productTour[productTourCurrentStep].followupQuestions.find(
        (q) => q.question === message
      )!
      const answer = question.answer

      setProductTourAnsweredQuestions((prev) => [...prev, question.id])
      setStreamedText(answer)
    },
    [setProductTourAnsweredQuestions, productTourCurrentStep, setStreamedText]
  )

  const handleNext = () => {
    setStreamedText('')
    setProductTourCurrentStep((prev) => prev + 1)
  }

  const handlePrevious = () => {
    setStreamedText('')
    setProductTourCurrentStep((prev) => prev - 1)
  }

  const handleFinish = () => {
    setStreamedText('')
    setProductTourAnsweredQuestions([])
    setShowProductTourView(false)
    setProductTourCurrentStep(0)
    // TODO: add tracking
  }

  return (
    <>
      <div className="relative hide-scrollbar h-full grow overflow-y-auto p-4">
        <ScrollToBottom
          className="h-full grow"
          followButtonClassName="hidden"
          scrollViewClassName="hide-scrollbar"
          debounce={100}
        >
          <ScrollableChatContainer>
            <Preamble size="lg" preamble={productTour[productTourCurrentStep].preamble} />
            {questionsAnsweredOnThisStep.map((q, i) => (
              <Fragment key={i}>
                <MessageCardMemoized
                  message={{
                    id: String(i),
                    content: q.question,
                    role: 'user'
                  }}
                />
                <MessageCardMemoized
                  message={{
                    id: String(i),
                    content:
                      // the last answer and text is currently "streaming"
                      i === questionsAnsweredOnThisStep.length - 1 && isStreaming
                        ? streamedText
                        : q.answer,
                    role: 'assistant'
                  }}
                />
              </Fragment>
            ))}
            {!isStreaming && (
              <InChatSuggestedPrompts
                isLoading={false}
                sendMessage={handleClick}
                suggestedPrompts={remainingQuestions.map((q) => q.question)}
                suggestedPromptsLoading={suggestedPromptsLoading}
                fullWidthProps={!isExpanded}
              />
            )}
          </ScrollableChatContainer>
        </ScrollToBottom>
      </div>
      <div className={cn(isFullscreen && 'mb-4')}>
        <ProgressInput
          currentStep={productTourCurrentStep}
          totalSteps={productTour.length}
          onNext={handleNext}
          onPrevious={handlePrevious}
          onFinish={handleFinish}
        />
      </div>
    </>
  )
}
